import React, { useEffect, useState } from "react";
import Tab from "./Tab";
import msft, { app } from "@microsoft/teams-js";
import { Spin } from "antd";

export const scopes = [`api://b3733c43-473f-499c-8b7d-b1310487b918/access_as_user`];

export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    app.initialize().then(async () => {
      app.notifySuccess();
      setLoading(false);
    });
  }, []);

  return (
    <Spin spinning={loading}>
      {!loading && <Tab />}
    </Spin>
  );
}
