import React, { useState, useEffect, useContext } from "react";
import { app, authentication } from "@microsoft/teams-js";
import { Button, Col, DatePicker, Form, Input, Row, Spin, Typography, message } from "antd";
import { Store } from "antd/es/form/interface";

const { Title } = Typography;

export default function Tab() {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const handleFormSubmit = async (values: Store) => {
    setLoading(true);
    try {
      let accessToken = await authentication.getAuthToken({ silent: true });

      let res = await fetch(
        `${process.env.REACT_APP_API_ADDRESS ?? ""}/AbsenceRequest`,
        {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            effective_date: new Date(), // this is ignored by the endpoint. absence request can only be submitted for current date.
            note_content: `${values.comment}`
          })
        }
      );

      if (res.status == 400) {
        messageApi.error(`You have already submitted an Absence Request for ${new Date().toLocaleDateString()}`);
        return;
      }

      messageApi.success("Absence request submitted successfully.", 10);
      form.resetFields();
    } catch (error) {
      console.error("Failed to submit absence request:", error);
      messageApi.error("Failed to submit absence request. Please try again." + error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
          <Spin spinning={loading}>
            <Title level={5}>Date: {new Date().toLocaleDateString()}</Title>
            <Form form={form} onFinish={handleFormSubmit}>
              <Form.Item
                name="comment"
                rules={[
                  { required: true, message: "Please enter your absence request comment." },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter your absence request comment" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Col>
      </Row>
    </>
  );
}
